import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "./index.css"
import Seo from "../components/seo"
import Footer from "../components/footer"

const TermsPage = () => {

  return (
    <div className="flex flex-col bg-black h-screen justify-between">
   

      <main>
        <div className="bg-hero-pattern h-96 -mb-100"></div>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1080,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          <div className="pt-16 flex justify-between">
            <div className="">
              <Link to="/">
                <StaticImage
                  src="../images/siyo-logo.png"
                  width={96}
                  quality={100}
                  alt="Si-Yo Music Logo"
                />
                <span> </span>
                <StaticImage
                  src="../images/ficec-logo.png"
                  width={110}
                  quality={100}
                  alt="FICEC Logo"
                />
              </Link>
            </div>

            {/* <div className="flex items-center  ">
              <NavMenu />
            </div> */}
          </div>

          <div className="text-white text-center font-extrabold pt-14 pb-6 text-2xl sm:text-5xl ">
            Terms and Conditions
          </div>

          <div className="pt-30 pb-4">
            <div>
              {" "}
              <p className="font-medium  text-lg text-center text-white ">
                In Progress
              </p>

            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default TermsPage
